import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-renton-washington.png'
import image0 from "../../images/cities/scale-model-of-hat-&-boots-park-(oxbow-park)-in-renton-washington.png"
import image1 from "../../images/cities/scale-model-of-seattle-chocolate-in-renton-washington.png"
import image2 from "../../images/cities/scale-model-of-oxbow-park-in-renton-washington.png"
import image3 from "../../images/cities/scale-model-of-maple-school-natural-area-in-renton-washington.png"
import image4 from "../../images/cities/scale-model-of-shadow-lake-nature-preserve-in-renton-washington.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Renton'
            state='Washington'
            image={image}
            lat='47.4796927'
            lon='-122.20792180000001'
            attractions={ [{"name": "Hat & Boots Park (Oxbow Park)", "vicinity": "6427 Carleton Ave S, Seattle", "types": ["point_of_interest", "establishment"], "lat": 47.5451051, "lng": -122.32169950000002}, {"name": "Seattle Chocolate", "vicinity": "1180 Andover Park W, Seattle", "types": ["food", "store", "point_of_interest", "establishment"], "lat": 47.44284409999999, "lng": -122.25422320000001}, {"name": "Oxbow Park", "vicinity": "6430 Corson Ave S, Seattle", "types": ["park", "point_of_interest", "establishment"], "lat": 47.5449647, "lng": -122.32160169999997}, {"name": "Maple School Natural Area", "vicinity": "1901 S Lucile St, Seattle", "types": ["park", "point_of_interest", "establishment"], "lat": 47.55318339999999, "lng": -122.30872019999998}, {"name": "SHADOW Lake Nature Preserve", "vicinity": "21656 184th Ave SE, Renton", "types": ["park", "university", "point_of_interest", "establishment"], "lat": 47.4070875, "lng": -122.0959823}] }
            attractionImages={ {"Hat & Boots Park (Oxbow Park)":image0,"Seattle Chocolate":image1,"Oxbow Park":image2,"Maple School Natural Area":image3,"SHADOW Lake Nature Preserve":image4,} }
       />);
  }
}